@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;
@import url('https://fonts.googleapis.com/css2?family=Imbue:opsz,wght@10..100,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
 
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.seva-header {
    display: flex;
    font-size: 3rem;
    font-weight: bold;
    color: $secondary_color_redish;
    
    &__title {
        border-bottom: 2px solid $secondary_color_redish;
    }
}

.count {
    max-width: 100%;
    // @include flex;
    display: flex;
    flex-direction: column;

    // border-top: 3px solid $primary_color_light-blue;
    // border-bottom: 3px solid $primary_color_light-blue;


    &-main {
        max-width: 100%;
        padding: 2rem 0.5rem;
        margin: 0rem 0rem;

        @include tablet {
            margin: 1rem 5rem;
        }

        @include desktop {
            margin: 1rem 10rem;
        }

    }

    &__title {
        display: flex;
        margin: 2rem 0rem;
        font-size: 1.75rem;
        font-family: "Imbue";
        color: $primary_color;
    }

    &__date {
        width: 40%;
        padding: 1rem;
        margin: 1rem 0rem;
        border: none;
        background-color: $primary_color_light-blue;
        color: $primary_color_white;
        border-radius: 0.25rem;
        font-weight: bold;

        @include tablet {
            width: 25%;
        }
    }

    &-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1rem 1.5rem;
        // border: 0.125rem solid $secondary_color_redish ;
        background-color: $primary_color_white;
        margin: 1rem 0rem;
        color: $primary_color_light-blue;
    }

    &-target {
        display: flex;
        align-items: center;
        font-size: 2rem;
        font-weight: 500;

        &__label {
            color: $secondary_color_black;
            font-size: 1rem;
            margin-right: 1rem;
        }
    }

    &-live {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin: 1rem;
        font-size: 7rem;
        font-weight: 500;

        @include tablet {
            font-size: 12rem;
        }

        &__samosa-image {
            height: 12rem;

            @include tablet {
                height: 15rem;
            }
        }

    }
}

.payment-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 0rem;

    @include tablet {
        flex-direction: row;
    }
}

.payment-value {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0.5rem 0rem;
    font-family: "Lilita One";


    &__link {
        width: 80%;
        background-color: $secondary_color_yellow;
        border-radius: 2rem;
        padding: 1rem 1rem;
        list-style-type: none;
        text-decoration: none;
        color: $primary_color_white;
        font-size: 1.5rem;
        transition: box-shadow 0.5s ease;
        transition: background-color 0.5s ease;

        &:hover {
            background-color: $background_color_brown;
            box-shadow: 0px 0px 20px rgba(134, 66, 3, 0.1);
            border: 2px solid rgb(251, 230, 0);
    
        }

        @include tablet {
            width: 50%;
        }

    }

}