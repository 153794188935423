@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.about {
    display: flex;
    flex-direction: column;
    // padding: 1rem 2rem;

    &__heading {
        background-color: #07588B;
        padding: 1rem 2rem;

    }

    &__title {
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 4rem;
        background-color: white;
        padding: 1rem;
        border-radius: 1rem;
        color: #07588B;
    }

    &__description {
        background-color: #27AEE8;
        border-radius: 2rem;
        padding: 1rem;
        position: relative;
        top: -1rem;
    }

    &__sevadaar {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #152544 ;
        max-width: 100%;
        padding: 1rem 2rem;

        @include tablet {
            flex-direction: row;
            justify-content: space-around;
            padding: 2rem 4rem;
        }


        &-img {
            max-width: 80%;
            height: 20rem;
            border-radius: 1.5rem;

            @include tablet {
                max-width: 30%;
            }
        }
    }

    &__seva {
        color: #152544;
        background-color: white;
        width: 80%;
        border-radius: 1.5rem;

        @include tablet {
            width: 65%;
        }
    }

    &__seva-bhangti--bani {
        font-weight: bold;
        font-size: 1.25rem;

    }

    &__seva-bhangti--transliteration {
        font-weight: 500;
        font-size: 1rem;

    }

}