@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}


.reels {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
    padding: 2rem 0.5rem;
    margin: 0rem 0rem;

    @include tablet {
        margin: 1rem 5rem;
    }

    @include desktop {
        margin: 1rem 10rem;
    }

    &__header {
        display: flex;
    font-size: 3rem;
    font-weight: bold;
    color: $secondary_color_redish;
    }

    &__container {
        margin: 2rem;
    }

}