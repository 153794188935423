@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.saroops {
    // border-top: 3px solid $primary_color_light-blue;
    padding: 1rem;
    background-color: #152544;

    &__Guru-Nanak-Dev-Ji--saroop {
        // border: 10px solid $secondary_color_yellow;
        box-shadow: 0px 2px 10px rgba(154, 124, 5, 0.4);
        border-radius: 1rem;
        height: 15rem;
    }

    &__sant {
        display: flex;
        justify-content: space-around;
    }

    &__Baba-Nand-Singh-Ji--saroop {
        height: 20rem;
    }

    &__Baba-Isher-Singh-Ji--saroop {
        height: 20rem;
    }
}

.gurshabad {
    color: white;
    font-weight: bold;
    font-size: 1.5rem
}