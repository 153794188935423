@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

.mobile{
    display: none;
}


.header {
    height: 7rem;
    display: flex;
    justify-content: center;
    margin: 1rem;
    // border-bottom: 0.125rem solid $primary_color_light-blue;

    @include tablet {
        height: 5rem;
        margin: 1rem 2rem;
    }

    @include desktop {
        margin: 1rem 8rem;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        // border-bottom: 2px solid grey;

        @include tablet {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__logo-container {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__logo {
        height: 3rem;
        border-radius: 2rem;
        transition: 0.25s ease;

        &:hover {
            height: 3.5rem;
            // border-bottom: 3px solid $primary_color_light-blue;
        }

        @include tablet {
            height: 4.5rem;

            &:hover {
                height: 5rem;
                // border-bottom: 3px solid $primary_color_light-blue;
            }
        }
    }

    &__links {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @include tablet {
            width: 30%;
        }

    }

    &__link {
        display: flex;
        text-align: center;
        list-style-type: none;
        text-decoration: none;

        @include tablet {
            width: 40%;
            margin: 0rem 0.5rem;

        }

        @include desktop {
            width: 50%;
            margin: 0rem 0.5rem;

        }
    }

    &__icon {
        font-size: 0.75rem;
        color: $primary_color_light-blue;
        border-bottom: 2px solid $primary_color_light-blue;
        // outline: 2px solid $primary_color;
        // border-radius: 50%;
        line-height: 2rem;
        transition: box-shadow 0.5s ease;
        transition: background-color 0.5s ease;
        transition: font-weight 0.125s ease;
        transition: height 0.25s ease, width 0.25s ease;


        &:hover {
            height: 2.75rem;
            width: 2.75rem;
            box-shadow: 0px 0px 0px 10px rgba(4, 39, 155, 0.13);
            background-color: $background_color_light-grey;
            // color: $primary_color_white;
            font-weight: bold;

        }

        @include tablet {
            width: 100%;
            // border-radius: 1rem;
            line-height: 2rem;
            font-size: 1rem;

            &:hover {
                height: 2.75rem;
                width: 100%;
                box-shadow: 0px 0px 0px 8px rgba(4, 39, 155, 0.13);
                background-color: $background_color_light-grey;
                // color: $primary_color_white;
                font-weight: bold;
    
            }
        }
    }

    &__spacer {
        display: none;

        @include tablet {
            display: none;

            width: 30%;
        }
        
    }
}